import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/layout/layout'
import MobileLayout from '../components/layout/mobileLayout'
import { FaqCategories } from '../components/FAQs/faqCategories'
import PageTitle from '../components/commonStyled/pageTitle'
import { renderOnMount } from '../components/forceUpdate'
import { useBP } from '../data/objects/breakpoints'
import SEO from '../components/layout/seo'
import { FAQTitle, FAQSubTitle } from '../components/FAQs/faqData'

export const pageForGAEvents = 'FAQs page'

const FaqPage = () => {
  const renderKey = renderOnMount()
  const { fitsB } = useBP().get()
  const data = useStaticQuery(graphql`
    query FAQTitleQuery {
      site {
        siteMetadata {
          faqMetadata {
            title
            description
          }
        }
      }
    }
  `)

  const content = (
    <>
      <SEO
        title={data.site.siteMetadata?.faqMetadata?.title}
        description={data.site.siteMetadata?.faqMetadata?.description}
      />
      <PageTitle title={FAQTitle} subtitle={FAQSubTitle} />
      <FaqCategories />
    </>
  )

  return fitsB ? (
    <MobileLayout key={renderKey} page={pageForGAEvents}>
      {content}
    </MobileLayout>
  ) : (
    <Layout key={renderKey} page={pageForGAEvents} transparentHeader={false}>
      {content}
    </Layout>
  )
}

export default FaqPage
