import React from 'react'
import feesTableMobile from '../../images/homepage/faqSection/fees_tabke_mobile.svg'
import feesTableDesktop from '../../images/homepage/faqSection/fees_table.svg'

const multipleItemsFee = `* For Jewelry Box (3 or more valuables), a flat fee of 18%* applies.`

const feesTableDescription = `
  For winning bids up to $5000 the fee is 18%.
  For winning bids from $5001 up to $15000 the fee is 14%.
  For winning bids from $15001 up to $30000 the fee is 12%.
  For winning bids above $30001 the fee is 10%.
`.replace(/\n+|\s{2, }/gmi, '')

// TODO check accessibility since `longdesc` is a deprecated attribute and `aria-description` is used wrong here
export const FeesTable = ({ isMobile }: { isMobile?: boolean }) => (<>
  <img
    src={`${isMobile ? feesTableMobile : feesTableDesktop}`}
    alt="Fees table"/>
  <br/>
  { multipleItemsFee }
</>)
