import React, { useState } from 'react'
import {
  SectionsList,
  SectionsToBeShown,
  SectionsPaths,
  SectionsColors,
  SectionsIcons, SectionsColorsHover
} from "./faqData";
import styled from 'styled-components'
import { Link } from 'gatsby'
import c from '../../styles/common.module.css'
import {
  GENERAL_COLOR_VALUES,
  GENERAL_FONT_VALUES,
  GeneralColorNames,
  GeneralFontNames, LineHeights,
  SpacingSizes
} from '../../../clarity'
import { useBP } from '../../data/objects/breakpoints'
import { renderOnMount } from '../forceUpdate'
import { fadeUpWrapper } from "../../animations/ScrollFadeUpAnimation";

const FAQSectionTilesMobile = styled.ul`
  padding: ${SpacingSizes.md} ${SpacingSizes.smd} 0;
  max-width: 1440px;
  margin: ${SpacingSizes.md} auto ${SpacingSizes.xxl};
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex-flow: column;
`

const FAQSectionTilesDesktop = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: ${SpacingSizes.md};
  --n: 4;
  margin: ${SpacingSizes.md} auto ${SpacingSizes.xxl};
  max-width: 1440px;
  padding: ${SpacingSizes.md} ${SpacingSizes.md} 0;
  justify-content: center;
`

const FAQSectionTileDesktop = styled.li`
  width: calc((100% - (var(--n) - 1) * ${SpacingSizes.md})/var(--n));
  max-width: 316px;
  aspect-ratio: 1.7/1;
  border-radius: ${SpacingSizes.xxs};
`

const FAQSectionTileMobile = fadeUpWrapper(styled.li`
  width: 100%;
  min-height: 90px;
  margin: 0 0 ${SpacingSizes.xs};
  border-radius: ${SpacingSizes.xxs};
`)

const FAQCategoryCaption = styled.span`
  font: ${GENERAL_FONT_VALUES[GeneralFontNames.playfair_22]};
  color: ${GENERAL_COLOR_VALUES[GeneralColorNames.worthy_black]};
  line-height: ${LineHeights[36]};
`

const FAQCategoryIcon = styled.i`
  width: 50px;
  height: 45px;
  background: no-repeat 50% 100%;
  background-size: contain;
  margin: ${(props: any) => props.isMobile ? '0 ' + SpacingSizes.sm + ' 0 ' + SpacingSizes.smd : '0 auto ' + SpacingSizes.sm}
`

export const FaqCategories = () => {
  const { fitsB, fitsC, fitsE } = useBP().get()
  const renderKey = renderOnMount()
  const isTouch = fitsB

  const FAQSectionTileList = isTouch ? FAQSectionTilesMobile : FAQSectionTilesDesktop
  const FAQSectionTile = isTouch ? FAQSectionTileMobile : FAQSectionTileDesktop

  return <FAQSectionTileList
    key={renderKey}
    className={[
  ]}>
    {
      SectionsToBeShown.map((section, idx) => {
        const [selected, setSelected] = useState(false)

        return <FAQSectionTile
          key={idx}
          className={[
            c.flexJustifyCenter,
            c.flexAlignCenter,
            c.dFlex,
            c.pointer,
            c.posRel,
          ]}
          onMouseEnter={() => { setSelected(true) }}
          onMouseLeave={() => { setSelected(false) }}
          style={{
            backgroundColor: SectionsColors[section],
            transition: 'box-shadow .2s ease',
            boxShadow: isTouch ? 'none' : `${selected ? '-6px 6px 0px': '0px 0px 0px'} ${SectionsColorsHover[section]}`,
          }}>
          <Link
            to={ SectionsPaths[section] }
            data-automation={`faq-category-tile-${SectionsList[section].replace(/\s/g, '-')}`}
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              flexDirection: isTouch ? 'row' : 'column',
              justifyContent: isTouch ? 'flex-start' : 'center',
              display: 'flex',
              alignItems: isTouch ? 'center' : 'normal',
              textAlign: 'center',
              textDecoration: 'none',
              color: GENERAL_COLOR_VALUES[GeneralColorNames.worthy_black],
            }}
            onBlur={() => { setSelected(false) }}
            onFocus={() => { setSelected(true) }}>
            <FAQCategoryIcon
              isMobile={isTouch}
              className={[c.dBlock]}
              style={{
                backgroundImage: `url(${SectionsIcons[section]})`
              }}/>
            <FAQCategoryCaption>{ SectionsList[section] }</FAQCategoryCaption>
          </Link>
        </FAQSectionTile>
      })
    }
  </FAQSectionTileList>
}
