import React from 'react'
import { WORTHY_PHONE } from '../../static-content/constants'
import { FeesTable } from '../commonStyled/feesTable'

import auction from '../../images/icons/faq/auction.svg'
import costs from '../../images/icons/faq/costs.svg'
import general from '../../images/icons/faq/general.svg'
import gettingPaid from '../../images/icons/faq/getting_paid.svg'
import grading from '../../images/icons/faq/grading.svg'
import insurance from '../../images/icons/faq/insurance.svg'
import pricing from '../../images/icons/faq/pricing.svg'
import shipping from '../../images/icons/faq/shipping.svg'

export enum Sections {
  general,
  pricing,
  shipping,
  insurance,
  service_costs,
  grading,
  auction,
  getting_paid,
}

export const FAQTitle = 'FAQs'
export const FAQSubTitle = 'Have your questions about Worthy answered.'

export const Knowledge = (section: Sections, isMobile?: boolean) =>
  ({
    [Sections.general]: [
      {
        Q: 'What does Worthy do?',
        A: (
          <p>
            Worthy is a jewelry selling partner dedicated to providing sellers with the ultimate selling experience. Our
            process includes free shipping, cleaning, taking studio photos and objective third party grading, followed
            by an online auction where our network of 1K professional buyers will compete for your jewelry. Worthy works
            to bring you the fairest market value for your jewelry and watches.
          </p>
        ),
      },
      {
        Q: 'What types of jewelry do you accept?',
        A: (
          <>
            <p>
              Worthy accepts jewelry pieces which will interest the professional buyers on our auction platform. We
              typically accept the following types of jewelry:
            </p>
            <ul>
              <li>
                <strong>Diamond Jewelry</strong> - This includes rings, bracelets, necklaces, and earrings made from
                gold or platinum.
              </li>
              <li>
                <strong>Designer Jewelry</strong> such as Tacori, Tiffany & Co, Cartier, Harry Winston,and Bvlgari.
              </li>
              <li>
                <strong>Branded watches</strong> - Luxury watches from brands including Rolex, Cartier, Omega,
                Breitling, Audemars Piguet, Patek Philippe, Tag Heuer, and many more.
              </li>
            </ul>
            <p>
              <a target="_blank" href="https://www.worthy.com/about/what-we-accept">
                View full list
              </a>
            </p>
          </>
        ),
      },
      {
        Q: 'How does it work?',
        A: (
          <>
            <p>There are three easy steps in our process:</p>
            <ol>
              <li>
                <strong>Preparing Your Jewelry </strong> - To begin the process, your jewelry is shipped to our office
                via a prepaid FedEx shipment that is insured by insurers at Lloyd’s of London. When it arrives, we prep
                it for auction, including cleaning, photography, and objective third-party grading by a world-class
                grading lab.
              </li>
              <li>
                <strong>The Online Auction</strong> - Once your piece is ready for auction, your auction manager will
                help you set a <a href="/faqs/pricing/#reserve-price">reserve price</a> you're comfortable with. We then
                create the auction listing for you and promote it on our buyer network. Your job is to set your reserve
                price and watch the offers roll in!
              </li>
              <li>
                <strong>Getting Paid</strong> - After the auction concludes successfully, you will receive the final
                sale amount minus our fee. Our fee is always a percentage of the price your jewelry was sold for.
                Depending on the payment method you select, the money will be sent to you within 1-5 business days.
              </li>
            </ol>
            <p>
              <a href="https://www.worthy.com/how-it-works/" target="_blank">
                Read more
              </a>{' '}
              about our process.
            </p>
          </>
        ),
      },
      {
        Q: 'Why Worthy?',
        A: (
          <>
            <p>
              Unlike local and online buyers, Worthy doesn’t have an agenda. We’re on your side. Really! Our process is
              set up so that when you make more, we make more. And we only make money if your jewelry sells for the
              price <strong>YOU</strong> are comfortable with. We do all of the work, from cleaning to grading to
              hosting the private auction. All you have to do is sit back and watch the offers roll in.
            </p>
          </>
        ),
      },
      {
        Q: 'Can I back out of the process at any time?',
        A: (
          <>
            <p>
              Prior to auction there is no obligation of any kind. During the preparation process, you will work with an
              auction manager to set a <a href="/faqs/pricing/#reserve-price">reserve price</a>. If the highest bid
              meets or exceeds your reserve price, you are committed to selling the piece. If the highest bid does not
              meet or exceed the reserve price you chose, you can opt to have the piece shipped back to you free of
              charge.
            </p>
          </>
        ),
      },
      {
        Q: 'How long does it take to sell with Worthy?',
        A: (
          <>
            <p>
              The process typically takes 10-14 business days from the time your jewelry arrives at Worthy headquarters
              to getting paid.
            </p>
          </>
        ),
      },
      {
        Q: 'What if my piece isn’t in perfect condition?',
        A: (
          <>
            <p>
              You can still sell imperfect jewelry! Jewelry and precious metals are appraised based on their weight and
              quality. Because of this, we can still sell them even if they are broken or incomplete. Watches, however,
              must be in proper working condition in order to sell.
            </p>
          </>
        ),
      },
      {
        Q: 'How long has Worthy been in business?',
        A: (
          <>
            <p>
              Worthy, Inc. was incorporated in 2011. Since then, we have helped over 35K satisfied clients earn over
              $200M through the sale of their jewelry and watches.
            </p>
          </>
        ),
      },
      {
        Q: 'Can I visit Worthy’s offices?',
        A: (
          <>
            <p>
              Yes, you can certainly make an appointment at our New York City office by calling us at{' '}
              <a href={`tel:${WORTHY_PHONE}`}>1&nbsp;(888)&nbsp;222-0208.</a>
            </p>
          </>
        ),
      },
      {
        Q: 'What are Worthy’s fees?',
        A: (
          <>
            <p>
              There is never an out of pocket cost to you. Our fees are subject to a successful auction and are based
              upon a percentage of the final sale price, as shown below. We share a common interest. We also only get
              paid when you do.
              <br />
              <span style={{ margin: '18px 0 12px', display: 'inline-block' }}>This is our fee table:</span>
              <br />
              <FeesTable isMobile={isMobile} />
            </p>
          </>
        ),
      },
    ],
    [Sections.shipping]: [
      {
        Q: 'Why do I have to ship my piece/s to Worthy?',
        A: (
          <>
            <p>
              In preparation for the auction, we must clean, photograph, and have your jewelry graded. This allows us to
              fully understand the value of your jewelry as well as create a listing which we send to buyers prior to
              your exclusive auction.
            </p>
          </>
        ),
      },
      {
        Q: 'How does shipping work?',
        A: (
          <>
            <p>
              Worthy will provide you with a prepaid shipping label and packing instructions. We can either schedule a
              free FedEx pickup at a time convenient for you, or you can drop off your package at a staffed FedEx
              location free of charge.
            </p>
          </>
        ),
      },
      {
        Q: 'Do I have to pay for shipping?',
        A: (
          <>
            <p>Nope! Worthy takes on the cost of shipping, and you pay absolutely nothing.</p>
          </>
        ),
      },
      {
        Q: 'Can I back out of the process after shipping?',
        A: (
          <>
            <ul>
              <li>
                You can request to have your piece sent back to you at any point prior to the auction. Once your{' '}
                <a href="/faqs/pricing/#reserve-price">reserve price</a> is set and the auction begins, you cannot
                request a return. If the auction ends and your reserve price is not met, you can have your piece sent
                back to you fully insured and free of charge.
              </li>
              <li>
                If you would like to have your jewelry returned to you, please contact our support team who will be able
                to assist you further with our return process.
              </li>
            </ul>
          </>
        ),
      },
      {
        Q: 'What should I include in my shipping box?',
        A: (
          <>
            <ul>
              <li>
                Your shipping box should include your jewelry and any protective accessories it requires. If the piece
                you would like to sell is from one of the{' '}
                <a href="https://www.worthy.com/about/what-we-accept" target="_blank">
                  jewelry or watch brands we accept
                </a>
                , please send it to us with any boxes or paperwork you have to help authenticate the item.
              </li>
              <li>
                Please note: For non-branded items (those purchased from a local jeweler or retailer), do not include
                any boxes or paperwork when shipping your piece. These boxes and paperwork will not be returned to you.
              </li>
            </ul>
          </>
        ),
      },
    ],
    [Sections.pricing]: [
      {
        Q: 'When will I get a price estimate for my piece/s?',
        A: (
          <>
            <p>
              In order to estimate the value of your jewelry we'll need to have your jewelry graded by a 3rd party
              grading lab following an in-house evaluation by our expert gemologists. This process will take place once
              your jewelry arrives in our offices and you will receive a recommended reserve price shortly after.
            </p>
          </>
        ),
      },
      {
        ID: 'reserve-price',
        Q: 'What is a reserve price?',
        A: (
          <>
            <p>
              The reserve price is the lowest amount you would be willing to sell your jewelry for. After we evaluate
              your piece and have it graded we will supply you with a recommended reserve price which should help your
              decision. If during the course of your auction a bid meets or exceeds the reserve price, that bid is
              binding. If the reserve price is not met, you can either accept the highest bid or have your jewelry
              returned to you, fully insured and free of charge.
            </p>
          </>
        ),
      },
      {
        Q: 'How will I know I priced my piece/s correctly?',
        A: (
          <>
            <p>
              After our experts evaluate your jewelry, we’ll give you a recommended reserve price and your dedicated
              auction manager will be there should you require additional advice. That should help you make an informed
              decision. Ultimately, your reserve should be a price which you are comfortable selling at.
            </p>
          </>
        ),
      },
      {
        Q: 'How is pre-owned diamond jewelry priced?',
        A: (
          <>
            <p>
              When you purchase a piece of diamond jewelry from a retailer/jeweler, the price of the jewelry includes
              costs such as mining and cutting the diamond, the setting and craftsmanship of creating the piece, as well
              as retail business expenses. When you, as the owner, decide to sell that piece of jewelry, it’s important
              to keep in mind that depreciation has occurred.
            </p>
            <p>
              Additionally, the buyer of your piece has costs to consider, such as whether the price of the diamond will
              cover their business expenses and how much additional labor will need to go into the piece before they can
              resell it (think resetting the diamonds or even recutting them). The setting of the jewelry will normally
              be reduced to its metal weight value.&nbsp;
            </p>
            <p>
              <a target="_blank" href="https://app.worthy.com/learn/how-to-price">
                Learn more
              </a>{' '}
              about pricing pre-owned diamond jewelry.
            </p>
          </>
        ),
      },
    ],
    [Sections.insurance]: [
      {
        Q: 'What happens if my piece/s get lost or stolen?',
        A: (
          <>
            <p>
              Your jewelry will be fully insured by insurers at Lloyd’s of London for up to $50K from the time you ship
              the item until it is either sold or returned to you. In the unlikely event that a loss occurs while the
              item is in our care, we will work with our insurers to file a claim and obtain reimbursement. See our{' '}
              <a href="https://www.worthy.com/about/terms-and-conditions" target="_blank">
                terms and conditions
              </a>{' '}
              for additional information about the insurance coverage and claims process.
            </p>
          </>
        ),
      },
      {
        Q: 'Does Worthy insure my jewelry?',
        A: (
          <>
            <p>
              Our{' '}
              <a href="https://www.worthy.com/about/terms-and-conditions" target="_blank">
                insurance coverage
              </a>{' '}
              for a jewelry piece in transit is up to an amount of $50K<sup>*</sup> based on the resale replacement
              value and is subject to the terms, limitations and requirements of our then current insurance policy (the
              “Insurance Coverage”).
              <br />
              <br />
              <sup>*</sup> Jewelry pieces estimated above $50K will receive additional coverage with pre-approval from
              Worthy at our sole discretion.
            </p>
          </>
        ),
      },
    ],
    [Sections.service_costs]: [
      {
        Q: 'What are Worthy’s fees?',
        A: (
          <>
            <p>
              There is never an out of pocket cost to you. Our fees are subject to a successful auction and are based
              upon a percentage of the final sale price, as shown below. We share a common interest. We also only get
              paid when you do.
              <br />
              <span style={{ margin: '18px 0 12px', display: 'inline-block' }}>This is our fee table:</span>
              <br />
              <FeesTable isMobile={isMobile} />
            </p>
          </>
        ),
      },
      {
        Q: 'How does Worthy make money?',
        A: (
          <>
            <p>
              Worthy only makes money when you make money. Our interests are aligned with yours: we want you to get the
              most money possible for your jewelry. When a piece successfully sells at auction, Worthy takes a
              percentage of the sale price.
              <br />
              <span style={{ margin: '18px 0 12px', display: 'inline-block' }}>This is our fee table:</span>
              <br />
              <FeesTable isMobile={isMobile} />
            </p>
          </>
        ),
      },
      {
        Q: 'Are there any additional costs?',
        A: (
          <>
            <p>
              No, there are no additional costs - Worthy’s success fee is deducted from the final sale amount and that’s
              it.
            </p>
          </>
        ),
      },
      {
        Q: 'Do I have to pay if I don’t end up selling?',
        A: (
          <>
            <p>If you don’t sell, you don’t pay anything. It’s risk-free!</p>
          </>
        ),
      },
    ],
    [Sections.grading]: [
      {
        Q: 'What if I already have a grading report?',
        A: (
          <>
            <p>
              Regardless of whether or not you have a grading report for your piece, we will send it, free of charge, to
              a world-class grading lab. We do this to ensure that you have an accurate and valid grading for your piece
              which will ultimately help you get a better price.
            </p>
          </>
        ),
      },
      {
        Q: 'Is an insurance appraisal the same as a grading report?',
        A: (
          <>
            <ul>
              <li>
                An insurance appraisal is not the same as a grading report. An insurance appraisal is created
                specifically for insurance purposes, assigning a value for the jewelry in the event that it is lost or
                stolen, and the value it gives your jewelry does not hold in other circumstances. Thus, in the specific
                context of a piece of jewelery’s resale value, an insurance appraisal cannot be used to determine the
                jewelry’s resale value. You can learn more about jewelry insurance appraisal{' '}
                <a
                  href="https://www.worthy.com/blog/knowledge-center/jewelry/how-much-is-my-jewelry-worth-1/"
                  target="_blank"
                >
                  {' '}
                  on our blog
                </a>
                .
              </li>
              <li>
                A diamond grading report is a document with the specific characteristics of your diamond including cut,
                color, clarity, and carat weight (size). The best diamond grading reports come from professional diamond
                grading labs, such as the GIA, GHI or IGI. Knowing these characteristics can help determine the value of
                your diamond at resale. Diamonds of a higher quality, with better characteristics, will fetch a higher
                price at auction than those of a lower quality.
              </li>
            </ul>
            <p>
              <a href="https://www.worthy.com/blog/knowledge-center/diamonds/gia-diamond-report/" target="_blank">
                Learn more
              </a>{' '}
              about diamond grading reports.
            </p>
          </>
        ),
      },
      {
        Q: 'Who are Worthy’s grading partners?',
        A: (
          <>
            <p>
              We partner with the Gemological Institute of America (GIA), the International Gemological Institute (IGI),
              GHI Gemological Laboratory and other industry leading labs for diamond jewelry and Central Watch for
              watches.
            </p>
          </>
        ),
      },
      {
        Q: 'Why does my diamond jewelry need to be graded?',
        A: (
          <>
            <p>
              A diamond grading report is an important tool for diamond sellers because it helps them and potential
              buyers determine what they have. When it comes time to determine a{' '}
              <a href="/faqs/pricing/#reserve-price">reserve price</a> for your piece, the diamond grading report will
              help you and your auction manager figure out the best price to work with. When your auction manager
              creates the listing, the characteristics and grading report help our professional buyers buy with
              confidence, knowing that the information they are getting about the diamond is coming from a trusted,
              third-party grading lab.
            </p>
            <p>
              <a href="https://www.worthy.com/blog/knowledge-center/diamonds/gia-diamond-report/" target="_blank">
                Learn more
              </a>{' '}
              about diamond grading reports.
            </p>
          </>
        ),
      },
    ],
    [Sections.auction]: [
      {
        Q: 'How does Worthy prepare my jewelry for auction?',
        A: (
          <>
            <p>
              Worthy’s expert gemologists will professionally clean and photograph your jewelry in HD, showing off every
              angle. Then we’ll have your pieces professionally graded by a professional third-party grading lab to
              provide you with objective information that you can use to set your own{' '}
              <a href="/faqs/pricing/#reserve-price">reserve price</a> for the auction.
            </p>
          </>
        ),
      },
      {
        ID: 'reserve-price',
        Q: 'What is a reserve price?',
        A: (
          <>
            <p>
              The reserve price is the lowest amount you would be willing to sell your jewelry for. After we evaluate
              your piece and have it graded we will supply you with a recommended reserve price which should help your
              decision. If during the course of your auction a bid meets or exceeds the reserve price, that bid is
              binding. If the reserve price is not met, you can either accept the highest bid or have your jewelry
              returned to you, fully insured and free of charge.
            </p>
          </>
        ),
      },
      {
        Q: 'Where does the auction take place?',
        A: (
          <>
            <p>
              The auction takes place online. We’ll virtually put your piece in front of 1K professional industry buyers
              from all over the world, and you can watch the bids roll in from the comfort of your own home.
            </p>
          </>
        ),
      },
      {
        Q: "What happens if my reserve price isn't met?",
        A: (
          <>
            <p>
              If the highest bid does not meet your <a href="/faqs/pricing/#reserve-price">reserve price</a>, you can
              either accept the bid or opt to have your jewelry returned to you, fully insured and free of charge.
            </p>
          </>
        ),
      },
      {
        Q: 'How long is the auction?',
        A: (
          <>
            <p>
              The auction typically lasts 48 hours. This gives buyers enough time to compete against each other for your
              jewelry, getting you the best offer.
            </p>
          </>
        ),
      },
      {
        Q: 'Who gets access to the auction?',
        A: (
          <>
            <p>You, as an observer, and over 1K professional buyers.</p>
          </>
        ),
      },
      {
        Q: 'Where will my jewelry be stored during the auction?',
        A: (
          <>
            <p>
              Your jewelry will be completely secure in our New York Office during the entire process in a safe which is
              under 24-hour surveillance.
            </p>
          </>
        ),
      },
      {
        Q: "What if my piece doesn't sell at auction?",
        A: (
          <>
            <p>
              If the highest bid does not meet your <a href="/faqs/pricing/#reserve-price">reserve price</a>, you can
              either accept the bid or opt to have your jewelry returned to you, fully insured and free of charge.
            </p>
          </>
        ),
      },
      {
        Q: 'Who are the buyers?',
        A: (
          <>
            <p>
              All 1K+ of our buyers are professionals in the diamond and jewelry industry. They’re experts who know the
              true value of jewelry, and they compete against each other for your piece, getting you the highest offers
              possible.
            </p>
          </>
        ),
      },
    ],
    [Sections.getting_paid]: [
      {
        Q: 'How will I receive payment for my piece/s?',
        A: (
          <>
            <p>
              You get to choose how you'll receive the payment. The options for payment are: via wire transfer, check,
              or Paypal. Once you've chosen your payment method, we take care of getting the money from the buyer to you
              as quickly as possible. <br />
              Please note:
            </p>
            <ul>
              <li>PayPal is not available for transactions over $5K.</li>
              <li>
                In some cases, we may need to verify your payment information over the phone after the auction. In that
                case payment may be delayed until we are able to reach you to complete the verification step.
              </li>
            </ul>
          </>
        ),
      },
      {
        Q: 'How long will it take to receive my payment?',
        A: (
          <>
            <p>
              Once the sale is complete, we collect payment from the buyer of your valuables. This usually occurs within
              5-7 business days, often much faster. Once we have received the funds, we begin processing your payment.
              Receipt of the payment varies depending on the payment method you select and works as follows:
            </p>
            <ul>
              <li>Bank transfer (ACH): 1-3 business days (usually the fastest payment method)</li>
              <li>PayPal: 2-3 business days</li>
              <li>Checks: 4-5 business days</li>
            </ul>
            <p>Payment for multiple pieces of jewelry is processed once all sales are complete.</p>
          </>
        ),
      },
    ],
  }[section])

export interface FAQ {
  ID?: string
  Q: string
  A: any
}

// TODO: think about the data structure
export const SectionsList = {
  [Sections.general]: 'General',
  [Sections.pricing]: 'Pricing',
  [Sections.shipping]: 'Shipping',
  [Sections.insurance]: 'Insurance',
  [Sections.service_costs]: 'Service costs',
  [Sections.grading]: 'Grading',
  [Sections.auction]: 'Auction',
  [Sections.getting_paid]: 'Getting paid',
}

export const SectionsPaths = {
  [Sections.general]: '/faqs/general/',
  [Sections.pricing]: '/faqs/pricing/',
  [Sections.shipping]: '/faqs/shipping/',
  [Sections.insurance]: '/faqs/insurance/',
  [Sections.service_costs]: '/faqs/service-costs/',
  [Sections.grading]: '/faqs/grading/',
  [Sections.auction]: '/faqs/auction/',
  [Sections.getting_paid]: '/faqs/getting-paid/',
}

export const SectionsIcons = {
  [Sections.general]: general,
  [Sections.pricing]: pricing,
  [Sections.shipping]: shipping,
  [Sections.insurance]: insurance,
  [Sections.service_costs]: costs,
  [Sections.grading]: grading,
  [Sections.auction]: auction,
  [Sections.getting_paid]: gettingPaid,
}

export const SectionsColors = {
  [Sections.general]: '#E3F7EA',
  [Sections.pricing]: '#D8F3FB',
  [Sections.shipping]: '#FFDEDE',
  [Sections.insurance]: '#ECE3ED',
  [Sections.service_costs]: '#FFEADE',
  [Sections.grading]: '#FEEBF5',
  [Sections.auction]: '#E3EEEE',
  [Sections.getting_paid]: '#FFF0DC',
}

export const SectionsColorsHover = {
  [Sections.general]: '#2D5C3B',
  [Sections.pricing]: '#1C8FA8',
  [Sections.shipping]: '#EE4338',
  [Sections.insurance]: '#B97CC1',
  [Sections.service_costs]: '#FF6B33',
  [Sections.grading]: '#F074B7',
  [Sections.auction]: '#4BBCBF',
  [Sections.getting_paid]: '#FBB863',
}

export const SectionsToBeShown = [
  Sections.general,
  Sections.pricing,
  Sections.shipping,
  Sections.insurance,
  Sections.service_costs,
  Sections.grading,
  Sections.auction,
  Sections.getting_paid,
]
